body {
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 17px;
    font-family: 'Montserrat', sans-serif !important;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
   
}
@supports(padding:max(0px)){
  .martop {
    padding-top: env(safe-area-inset-top);
  }
 
}
@font-face {
  font-family: 'ScriptinaPro';
  src: url("/src/fonts/Scriptina_Pro.woff") format("woff");
  font-weight: normal;
  font-style: italic;
       
}

img.bottom {
    transform: rotate(3deg);
    -webkit-transform: rotate(3deg);
    -moz-transform: rotate(3deg);
    -ms-transform: rotate(3deg);
    -o-transform: rotate(3deg);
}

.box-shadow {
    border-radius: 50px !important;
    -webkit-border-radius: 50px !important;
    -moz-border-radius: 50px !important;
    -ms-border-radius: 50px !important;
    -o-border-radius: 50px !important;
}

.btn-primary {
    background: #005284 !important;
}

.btn-primary:hover,
.btn-primary:active {
    background: #005284 !important;
}

.ReactCollapse--collapse {
    transition: height 280ms cubic-bezier(0.4, 0, 0.2, 1);
}

.steps {
    display: flex;
}

p {
    margin: 0;
}

.step {
    height: 5px;
    background: #fff;
    margin: 4px;
    border-radius: 10px;
}

.active.step,
.done.step {
    background-color: #005284;
}

.MuiSelect-select {
    padding: 12px !important;
}
.test{
    width:200px;
    margin-right: 70px;
    margin-top:-12px;
}
.animate-charcter
{
    
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    rgba(190,31,36,1) 67%,
    #005284 100%
  );
  
  background-clip: border-box;
  background-size: 200% auto;
  
  color: grey;
  position: absolute;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
      font-size: 50px;
}
@media (min-width: 600px){

.css-6t8yfa-MuiToolbar-root {
  min-height: 0px !important;

}
}
@keyframes textclip {
  to {
    background-position: 200% center;
  }
}
.wrapper {
   
    display:inline-block;
    padding: 10px;
    height: 20px;
    width:auto;
}



.contents {
    white-space:nowrap;
    margin-left: -1em;
    padding-left: 1em;
}

.inner {
    display:inline-block;
    width:0%;
    overflow:hidden;
    -webkit-transition: width 1s ease-in-out;
    -moz-transition: width 1s ease-in-out;
    -o-transition: width 1s ease-in-out;
    transition: width 1s ease-in-out;
    
}

  
  #floating-button{
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background: #db4437;
    position: fixed;
    bottom: 30px;
    right: 30px;
    cursor: pointer;
    box-shadow: 0px 2px 10px rgba(0,0,0,0.2);
  }
  
  .plus{
    color: white;
    position: absolute;
    top: 0;
    display: block;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    padding: 0;
    margin: 0;
    line-height: 55px;
    font-size: 38px;
    font-family: 'Roboto';
    font-weight: 300;
    animation: plus-out 0.3s;
    transition: all 0.3s;
  }
  
  #container-floating{
    position: fixed;
    width: 70px;
    height: 70px;
    bottom: 30px;
    right: 30px;
    z-index: 50px;
  }
  
  #container-floating:hover{
    height: 400px;
    width: 90px;
    padding: 30px;
  }
  
  #container-floating:hover .plus{
    animation: plus-in 0.15s linear;
    animation-fill-mode: forwards;
  }
  
  .edit{
    position: absolute;
    top: 0;
    display: block;
    bottom: 0;
    left: 0;
    display: block;
    right: 0;
    padding: 0;
    opacity: 0;
    margin: auto;
    line-height: 65px;
    transform: rotateZ(-70deg);
    transition: all 0.3s;
    animation: edit-out 0.3s;
  }
  
  #container-floating:hover .edit{
    animation: edit-in 0.2s;
     animation-delay: 0.1s;
    animation-fill-mode: forwards;
  }
  
  @keyframes edit-in{
      from {opacity: 0; transform: rotateZ(-70deg);}
      to {opacity: 1; transform: rotateZ(0deg);}
  }
  
  @keyframes edit-out{
      from {opacity: 1; transform: rotateZ(0deg);}
      to {opacity: 0; transform: rotateZ(-70deg);}
  }
  
  @keyframes plus-in{
      from {opacity: 1; transform: rotateZ(0deg);}
      to {opacity: 0; transform: rotateZ(180deg);}
  }
  
  @keyframes plus-out{
      from {opacity: 0; transform: rotateZ(180deg);}
      to {opacity: 1; transform: rotateZ(0deg);}
  }
  
  .nds{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: fixed;
    z-index: 300;
    transform:  scale(0);
    cursor: pointer;
  }
  
  .nd1{
    background: #d3a411;
    right: 40px;
    bottom: 120px;
    animation-delay: 0.2s;
      animation: bounce-out-nds 0.3s linear;
    animation-fill-mode:  forwards;
  }
  
  .nd3{
    background: #3c80f6;
    right: 40px;
    bottom: 180px;
    animation-delay: 0.15s;
      animation: bounce-out-nds 0.15s linear;
    animation-fill-mode:  forwards;
  }
  
  .nd4{
    background: #ba68c8;
    right: 40px;
    bottom: 240px;
    animation-delay: 0.1s;
      animation: bounce-out-nds 0.1s linear;
    animation-fill-mode:  forwards;
  }
  
  @keyframes bounce-nds{
      from {opacity: 0;}
      to {opacity: 1; transform: scale(1);}
  }
  
  @keyframes bounce-out-nds{
      from {opacity: 1; transform: scale(1);}
      to {opacity: 0; transform: scale(0);}
  }
  
  #container-floating:hover .nds{
    
    animation: bounce-nds 0.1s linear;
    animation-fill-mode:  forwards;
  }
  
  #container-floating:hover .nd3{
    animation-delay: 0.08s;
  }
  #container-floating:hover .nd4{
    animation-delay: 0.15s;
  }
  #container-floating:hover .nd5{
    animation-delay: 0.2s;
  }
  
  .letter{
    font-size: 23px;
    font-family: 'Roboto';
    color: white;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0;
    top: 0;
    bottom: 0;
    text-align: center;
    line-height: 40px;
  }
  
  .reminder{
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0;
    line-height: 40px;
  }
  
  .profile{
    border-radius: 50%;
    width: 40px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 20px;
  }

.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}

/*simple-lightbox.min.js上書き*/
.sl-overlay {
  opacity: 0.8 !important;
  background: #000;
}
.sl-wrapper.js_LightboxWrapper button,
.sl-wrapper.js_LightboxWrapper .sl-counter,
.sl-spinner {
  color: #fff;
}
.sl-spinner {
  opacity: 0.9;
}
.sl-wrapper.js_LightboxWrapper .sl-counter {
  font-size: 16px;
}
.sl-wrapper.js_LightboxWrapper .sl-close {
  font-size: 40px;
}
.sl-wrapper.js_LightboxWrapper button.sl-prev,
.sl-wrapper.js_LightboxWrapper button.sl-next {
  top: 40%;
  height: 150px;
  font-size: 60px;
}
.sl-wrapper.js_LightboxWrapper .sl-prev {
  left: 80px;
}
.sl-wrapper.js_LightboxWrapper .sl-next {
  right: 80px;
}
.sl-image {
  display: table;
}
.sl-additional-html {
  display: table-cell;
  padding: 20px;
  vertical-align: top;
  color: #fff;
  background: #000;
}

.sl-wrapper.js_LightboxWrapper .sl-image .sl-caption {
  font-size: 16px;
}

.caption {
  background: #000;
  color: #fff;
}

/* Notification icon shake  */
.loader,
.loader:before,
.loader:after {
  background: #1d53bf;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color: #1d53bf;
  text-indent: -9999em;
  margin: 10px auto;
  position: relative;
  font-size: 3px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

.notificationshake {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) infinite;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
/* hiding scrollbar in Chrome,Opera & Safari */
.hidesb::-webkit-scrollbar {
  display: none;
}
/* hiding scrollbar in IE & FF */

.hidesb {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}